import React from 'react';

import { layoutComponents } from './layoutComponents';

interface LazyComponentProps {
  type: string;
  id: string;
  index: number;
}

export const preloadComponent = ({ type }: Pick<LazyComponentProps, 'type'>) => {
  const Component = layoutComponents[type as keyof typeof layoutComponents];
  Component.preload();
};

export const LazyComponent: React.FC<React.PropsWithChildren<LazyComponentProps>> = ({
  type,
  ...props
}) => {
  const Component = layoutComponents[type as keyof typeof layoutComponents];

  if (!Component) {
    return null;
  }

  return (
    <Component
      type={type}
      {...props}
    />
  );
};
